<template>
  <f7-page class="explore-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <HomeNavigationComponent search="explore" @searched="onSearch" />

      <f7-fab position="center-bottom" href="/post/create/">
        <font-awesome-icon :icon="['far', 'plus']" />
      </f7-fab>
    </template>

    <section class="post-container">
      <PostListComponent ref="postListComponent" mode="explore" :size="16" />
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, defineAsyncComponent } from "vue";

// import HomeNavigationComponent from "@/components/navigations/HomeNavigationComponent.vue";
// import PostListComponent from "@/components/PostListComponent.vue";

const HomeNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "home-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/HomeNavigationComponent.vue"));
const PostListComponent  = defineAsyncComponent(() => import(/* webpackChunkName: "post-list" */ /* webpackMode: "lazy" */ "@/components/PostListComponent.vue"));

export default defineComponent({
  name: "ExplorePage",
  components: {
    HomeNavigationComponent,
    PostListComponent,
  },
  props: { f7router: Object },
  setup() {
    const postListComponent = ref(false);
    const showPreloader = ref(false);

    return { postListComponent, showPreloader };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      await this.$refs.postListComponent.loadMore();
      this.showPreloader = false;
    },
    onSearch(searchValue) {
      this.$refs.postListComponent.search(searchValue);
    },
  },
});
</script>

<style scoped>
.bg-gray {
  width: 100%;
  min-height: 735px;
}
</style>
